const words = [
  "алкого́ль",
  "алфави́т",
  "аристокра́тия",
  "бало́ванный",
  "балова́ть",
  "бензопрово́д",
  "бряца́ние",
  "букси́ровать",
  "буржуази́я",
  "Ва́льтер",
  "ве́черя",
  "втри́дорога",
  "газопрово́д",
  "гу́сеничный",
  "дефи́с",
  "диспансе́р",
  "добела́",
  "добы́ча",
  "догово́р",
  "докуме́нт",
  "доне́льзя",
  "до́низу",
  "доска́",
  "дремо́та",
  "единовре́менный",
  "жа́ловать",
  "жалюзи́",
  "жаро́венный",
  "зави́дно",
  "за́гнутый",
  "за́говор",
  "за́годя",
  "заку́порить",
  "запломбирова́ть",
  "зна́мение",
  "зубча́тый",
  "избало́ванный",
  "и́конопись",
  "индустри́я",
  "испове́дание",
  "исче́рпать",
  "ка́мбала",
  "кардига́н",
  "катало́г",
  "кварта́л",
  "кедро́вый",
  "киломе́тр",
  "кожу́х",
  "коклю́ш",
  "коры́сть	",
  "костюмиро́ванный",
  "краси́вее",
  "кровоточи́ть",
  "ку́хонный",
  "ламини́ровать",
  "ла́цкан",
  "ломо́та",
  "ма́нтра",
  "мастерски́",
  "метрополите́н",
  "мозаи́чный",
  "мусоропрово́д",
  "на́бок",
  "надо́лго",
  "наме́рение",
  "нарочи́то",
  "на́чатый",
  "нефтепрово́д",
  "облегчи́ть",
  "оксю́морон",
  "опо́шлить",
  "опто́вый",
  "осве́домить",
  "отку́порить",
  "о́трочество",
  "парте́р",
  "переходни́к",
  "плодоноси́ть",
  "пломбирова́ть",
  "пломбиро́вщик",
  "поня́вший",
  "по́нятый",
  "портфе́ль",
  "премирова́ть",
  "прину́дить",
  "гербы́",
  "путепрово́д",
  "равно́",
  "разоружи́ть",
  "ру́бчатый",
  "са́кура",
  "си́лос",
  "сли́вовый",
  "сосредото́чение",
  "столя́р",
  "телепа́тия",
  "трансфе́р",
  "убы́ть",
  "углуби́ть",
  "удо́брить	",
  "украи́нский",
  "фено́мен",
  "фети́ш",
  "хво́я",
  "хода́тайство",
  "хода́тайствовать",
  "хребе́т",
  "христиани́н",
  "цеме́нт",
  "це́нтнер",
  "цепо́чка",
  "че́рпать",
  "шарово́й",
  "щаве́ль",
  "э́кскурс",
  "эпи́граф",
  "то́рты",
  "ба́нты",
  "аниме́",
  "аппостро́ф",
  "во́ры",
  "взята́",
  "догово́ры",
  "занята́",
  "кре́мы",
  "кре́мов",
  "ла́тте",
  "начался́",
  "о́бняли",
  "сре́дства",
  "по́дняли",
  "ша́рфы",
  "снята́",
  "но́гтя",
  "че́рпая",
  "снабжена́",
  "лгала́",
  "сверли́т",
  "включи́м",
  "прибыла́",
  "нача́вшись",
  "бо́мжи",
  "ле́кторов",
  "про́дал",
  "вруча́т",
  "маршме́ллоу",
  "рандо́мный",
  "стригу́",
  "сре́дствами",
  "грунто́вы"
];
export default words;
